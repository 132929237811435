import "../css/what.css";
import Title from "./Title";
import building from "../assets/building.png";
import vr from "../assets/vr.png";
import Check from "./Check";
import Accordion from "./Accordion";
import problemsPdf from "../assets/The Problem.pdf"; // Make sure to add your PDF file to this location

const items = [
	"Faster Investment-to-Exit Cycle – Invest in businesses with streamlined incorporation, scaling, and structured exits, ensuring faster liquidity and reduced risk.",
	"Higher Return Potential – Access curated, high-growth investment opportunities in startups, scaleups, and franchises, optimized for profitability.",
	"Diversified & De-Risked Investments – Benefit from structured alternative investment strategies that mitigate risk while maximizing long-term gains.",
	"Hands-Off Investment Growth with Expert Support – Gain access to mentorship-driven investments, allowing businesses to scale efficiently while you focus on portfolio growth.",
	"Industrialized Alternative Investment Access – Unlike traditional investment models, csPILLAI® systematizes and digitizes investment processes, enabling seamless one-click investing and exits.",
];

const accordionItems = [
	{
		title: "Alternative Investments: A Market in Need of Structure",
		content: (
			<>
				<div className='manrope'>
					Alternative investments—spanning startups, scaleups, franchises,
					mentorship-driven investments, real estate, and beyond—offer
					high-growth opportunities. However, the lack of a seamless, one-click
					digital investment and exit process has made this sector complex and
					inefficient for investors and entrepreneurs alike.
				</div>
			</>
		),
	},
	{
		title: "Challenges Hindering Alternative Investments",
		content: (
			<>
				<div style={{ marginBottom: "10px" }}>
					Despite its potential, alternative investing faces key structural
					challenge
				</div>
				<ul style={{ paddingLeft: "30px" }}>
					<li>
						<b>Limited Awareness Among Investors –</b> Many investors remain
						unaware of structured alternative investment opportunities across
						different financial capacities.
					</li>
					<li>
						<b>Fragmented Investment Ecosystem – </b>No industrialized,
						digital-first process exists for investing, scaling, and exiting
						high-growth ventures remotely with just one click.
					</li>
					<li>
						<b>Lack of Structured Support & Mentorship –</b> Founders struggle
						to find centralized guidance for transitioning from ideation to
						sustainable businesses.
					</li>
					<li>
						<b>Capital Bottlenecks for Innovators –</b> Self-employed inventors
						lack scalable financial backing.
					</li>
				</ul>
			</>
		),
	},
	{
		title: "csPILLAI®: Industrializing Alternative Investments with BVB",
		content: (
			<>
				<div>
					Founded by{" "}
					<b>
						Sureshbabu C. Pillai, csPILLAI® is transforming alternative
						investments
					</b>{" "}
					through its proprietary{" "}
					<b>Broad Venture Builder (BVB) model— a structured framework</b> that
					democratizes access, optimizes capital efficiency, and mitigates risk.
				</div>
				<div style={{ marginTop: "20px", marginBottom: "20px" }}>
					The <b>BVB model</b> provides structured investment opportunities
					across all investor classes through:
				</div>
				<ul style={{ paddingLeft: "30px" }}>
					<li>
						<b>Startup Investments –</b> One-click access to{" "}
						<b>idea-stage startup investment opportunities.</b>
					</li>
					<li>
						<b>Scaleup Investments </b> – One-click access to{" "}
						<b>high-growth business investment opportunities.</b>
					</li>
					<li>
						<b>Franchise Investments – </b> One-click access to{" "}
						<b>structured franchise investment opportunities.</b>
					</li>
					<li>
						<b>Mentorship-Driven Investments</b> – One-click access to{" "}
						<b>
							investment opportunities in strategic mentorship to scale one's
							own startup.
						</b>
					</li>
				</ul>
			</>
		),
	},
	{
		title: "csPILLAI®: The Future of Alternative Investing",
		content: (
			<>
				<p>
					<b>csPILLAI® is not an investor, incubator, accelerator, or VC.</b> It
					is a
					<b>
						{" "}
						fully industrialized investment ecosystem and alternative
						investments facilitator,
					</b>{" "}
					providing{" "}
					<b>
						structured investment opportunities, venture-building, mentorship,
						and seamless exits
					</b>{" "}
					—creating <b>scalable, high-yield pathways</b> for investors and
					entrepreneurs alike.
				</p>
			</>
		),
	},
];

function What() {
	return (
		<div id='what?' className='container bg what_cnt flex mfc justify-between'>
			<div className='pm'>
				<div className='title manrope'>
					<span
						style={{ color: "#F57059", fontWeight: "700", fontSize: "0.6em" }}
					>
						CsPILLAI
					</span>
					<a
						href={problemsPdf}
						download='Problems-We-Solve.pdf'
						style={{ textDecoration: "none", color: "inherit" }}
					>
						<h2 style={{ fontWeight: "800" }}>
							The{" "}
							<span
								style={{
									color: "#F57059",
								}}
							>
								Problems
							</span>{" "}
							We Solve
						</h2>
					</a>
				</div>
				<p className='manrope'>
					csPILLAI®: Systematizing, Standardizing, Formalizing, Legitimizing,
					Digitizing, and Institutionalizing Alternative Investments through the
					Broad Venture Builder (BVB) Model
				</p>

				<Accordion items={accordionItems} />

				<div
					style={{ backgroundImage: `url(${building})` }}
					className='bg_img'
				></div>
			</div>
			<div className='pm'>
				<Title subtitle='csPILLAI' title='Benefits' />
				<p className='manrope'>
					By choosing <b>csPILLAI®</b> as your investment platform, you unlock
					structured, high-impact advantages that maximize growth and returns:
				</p>

				<ul className='ulw manrope'>
					{items.map((i, idx) => (
						<li key={idx}>
							<Check />
							{i}
						</li>
					))}
				</ul>
				<div
					style={{ backgroundImage: `url(${vr})` }}
					className='vr_cnt desktop'
				></div>
			</div>
		</div>
	);
}

export default What;
