import Title from "./Title";
import i01 from "../assets/svg/obj/01.svg";
import i02 from "../assets/svg/obj/02.svg";
import i03 from "../assets/svg/obj/05.svg";
import i04 from "../assets/svg/obj/04.svg";
import "../css/objectives.css";

const objectives = [
	{
		svg: i01,
		header: "Drive AI-Powered Startup Growth",
		note: "Develop 20+ high-potential startups annually, ensuring structured capital infusion, Series A readiness, and operational profitability within 540 days.",
	},
	{
		svg: i02,
		header: "Institutionalize Scaleup & Exit Pathways",
		note: "Launch 5+ co-owned scaleups annually, enabling structured growth, investor-backed scaling, and IPO or strategic acquisition within 1080 days of Series A funding.",
	},
	{
		svg: i03,
		header: "Expand Entrepreneurial Investment Networks",
		note: "Facilitate 50-100 investor-driven ventures annually, accelerating entrepreneurship through structured Franchise Programs and mentorship-backed capital access.",
	},
	{
		svg: i04,
		header: "Build Investment-Backed Startup Hubs",
		note: "Establish 3-5 startup estates annually, integrating franchise-backed rural business models, structured investments, and mentorship-driven scalability.",
	},
];

function Objectives(props) {
	return (
		<div className='container bg containerh'>
			<Title title='Our Objectives' />
			<div className='flex mfc manrope objs'>
				{objectives.map((o, idx) => (
					<div className='gli align-center flex' key={idx}>
						<div className='g_ob flex  justify-center align-center'>
							<div
								style={{ backgroundImage: `url(${o.svg})` }}
								className='img_ob'
							></div>
						</div>
						<div>
							<p style={{ fontWeight: 600, textDecoration: "underline" }}>
								{o.header}
							</p>
							<p>{o.note}</p>
						</div>
					</div>
				))}
			</div>
		</div>
	);
}

export default Objectives;
