import Title from "./Title";
import aboutImg from "../assets/about.png";
import "../css/about.css";
import founder from "../assets/founder.png";
import pdf from "../assets/FilePdf.png";
import profile from "../assets/Profile1.pdf";
import Check from "./Check";
import NewAccordion from "./NewAccordion";

const items = [
	"Venture Building",
	"Platform Services",
	"Auctions & Collaborations",
	"Franchising",
	"Mentorship-Driven Investments",
];

const accordionItems = [
	{
		title: "Welcome to csPILLAI®: Institutionalizing Alternative Investments",
		content: (
			<>
				<div className='manrope' style={{ textAlign: "start" }}>
					At <b>csPILLAI®,</b> we{" "}
					<b>
						do not operate as a private equity firm, VC, or traditional
						investment fund.
					</b>{" "}
					Instead, we are an <b>Alternative Investment Facilitator,</b>{" "}
					revolutionizing how <b>retail and institutional investors</b> access
					structured, scalable, and high-impact investment opportunities.
					<br />
					<br />
					Our proprietary{" "}
					<b>
						Broad Venture Builder (BVB) model systematizes alternative
						investments,
					</b>{" "}
					offering seamless access to diversified, high-growth opportunities
					across:
					<ul className='ulw'>
						{items.map((i, idx) => (
							<li key={idx}>
								<Check />
								{i}
							</li>
						))}
					</ul>
					Unlike traditional investment firms,{" "}
					<b>csPILLAI® industrializes alternative investments</b>—bridging{" "}
					<b>
						investors, scalable business models, and structured exit strategies
					</b>{" "}
					through <b>a fully digitized and streamlined process.</b>
					<br />
					<br />
				</div>
			</>
		),
	},

	{
		title: "Beyond Traditional Investments",
		content: (
			<>
				<div className='manrope' style={{ textAlign: "start" }}>
					Our investment ecosystem transcends conventional asset classes like
					stocks and bonds, focusing on structured, high-yield alternative
					investment opportunities in:
				</div>
				<ul
					style={{
						paddingLeft: "30px",
						textAlign: "start",
						gap: "6px",
						display: "flex",
						flexDirection: "column",
					}}
				>
					<li>
						<b>Startup Investments –</b>Enabling investors to back idea-stage
						ventures through a structured process.
					</li>
					<li>
						<b>Venture-Backed Scaleups – </b>Providing investment access to
						businesses poised for exponential growth.
					</li>
					<li>
						<b>Franchise-Based Investments –</b> Allowing structured entry into
						scalable franchise-backed businesses.
					</li>
					<li>
						<b>Commodities & Strategic Assets –</b> Offering alternative
						investment routes for portfolio diversification.
					</li>
				</ul>
				<div style={{ marginTop: "10px", textAlign: "start" }}>
					We do not act as direct investors but facilitate and structure
					alternative investments through a risk-optimized, technology-driven
					platform.
				</div>
			</>
		),
	},
	{
		title: "Advantages of Investing with csPILLAI®",
		content: (
			<>
				<ul
					style={{
						paddingLeft: "30px",
						textAlign: "start",
						gap: "6px",
						display: "flex",
						flexDirection: "column",
					}}
				>
					<li>
						<b>Enhanced Portfolio Diversification –</b> Expand beyond
						traditional assets into structured alternative investments.
					</li>
					<li>
						<b>Optimized Risk Management –</b> Reduce volatility through
						strategic investment structuring and digital due diligence.
					</li>
					<li>
						<b>High-Yield Investment Potential –</b> Maximize returns through
						structured alternative investment models.
					</li>
				</ul>
				<div style={{ marginTop: "10px", textAlign: "start" }}>
					As alternative assets gain prominence, structured investment access is
					crucial for long-term financial success. csPILLAI® ensures a seamless,
					scalable, and responsible investment experience for all.
				</div>
			</>
		),
	},
	{
		title: "The Vision Behind csPILLAI®",
		content: (
			<>
				<div style={{ marginTop: "10px", textAlign: "start" }}>
					<div className='img'>
						<div
							// style={{ backgroundImage: `url(${founder})` }}
							className='pimg'
						>
							<img src={founder} alt='' />
						</div>
						<p style={{ color: "#F57059" }}>C.S. PILLAI (1940-1996)</p>
					</div>
					<p>
						The Broad Venture Builder (BVB) model at the core of csPILLAI® is
						designed to drive:
					</p>
					<p>✔ Innovation</p>
					<p>✔ Entrepreneurship</p>
					<p>✔ Employment</p>
					<p>✔ Rural Development</p>
				</div>

				<div style={{ marginTop: "10px", textAlign: "start" }}>
					This philosophy is inspired by the vision of C.S. Pillai (1940-1996),
					a pioneering S.C. Railway loco pilot from Gooty R.S., India.
				</div>

				<div style={{ marginTop: "10px", textAlign: "start" }}>
					csPILLAI® honors his legacy by structuring scalable investment models
					that empower entrepreneurs, investors, and communities.
				</div>
			</>
		),
	},
	{
		title: "Financial Success with Social Responsibility",
		content: (
			<>
				<div className='manrope' style={{ textAlign: "start" }}>
					At its core, csPILLAI® integrates financial success with social
					impact, striving to institutionalize responsible investing while
					fostering economic and societal well-being.
				</div>
				<p>Our philosophy is simple:</p>
				<ul
					style={{
						paddingLeft: "30px",
						textAlign: "start",
						gap: "6px",
						display: "flex",
						flexDirection: "column",
					}}
				>
					<li>
						<b>"Creating wealth while driving meaningful change"</b>
						—leveraging structured, value-driven innovations to generate
						sustainable returns while advancing economic empowerment.
					</li>
				</ul>
				<div style={{ marginTop: "10px", textAlign: "start" }}>
					By bridging financial growth with social responsibility, csPILLAI®
					builds a structured alternative investment ecosystem that benefits
					investors, entrepreneurs, communities, and the global economy.
				</div>
			</>
		),
	},
	{
		title: (
			<div className='manrope' style={{ textAlign: "start" }}>
				Join the Future of Alternative Investing with csPILLAI®
			</div>
		),
		content: (
			<>
				<div className='manrope' style={{ textAlign: "start" }}>
					Step into a revolutionized investment landscape, where alternative
					investments are systematized, accessible, and optimized for long-term
					value creation.
				</div>
			</>
		),
	},
];

function About() {
	return (
		<div className='container about'>
			<br />
			<br />

			<Title title='About Us' />
			<div className='flex mfc abt'>
				<div
					style={{ backgroundImage: `url(${aboutImg})` }}
					className='img_abt'
				></div>
				<div className='infs'>
					<div className='tnxs'>
						<Title title='Aim' />
						<p className='manrope'>
							Our goal is to{" "}
							<b>institutionalize and streamline alternative investments</b> by
							providing structured opportunities and fostering growth through:
							<ol>
								<li>
									<span style={{ fontWeight: 600 }}>
										Empowering Retail Investors –
									</span>{" "}
									Providing seamless access to{" "}
									<b>structured alternative investment opportunities,</b>{" "}
									enabling individuals to invest in{" "}
									<b>
										startups, scaleups, franchise ventures, and
										mentorship-driven businesses.
									</b>
								</li>
								<li>
									<span style={{ fontWeight: 600 }}>Driving Innovation –</span>{" "}
									Encouraging <b>entrepreneurs and manufacturing partners</b> to
									join <b>csPILLAI®’s investment ecosystem,</b> fostering{" "}
									<b>disruptive innovation and scalable business growth.</b>
								</li>
								<li>
									<span style={{ fontWeight: 600 }}>
										Attracting Top Industry Expertise –
									</span>{" "}
									Engaging{" "}
									<b>
										seasoned founders, industry leaders, and investment experts
									</b>{" "}
									as <b>strategic mentors and partners,</b> strengthening{" "}
									<b>csPILLAI®-driven ventures.</b>
								</li>
								<li>
									<span style={{ fontWeight: 600 }}>
										Securing Capital for Growth –
									</span>{" "}
									Building{" "}
									<b>high-impact partnerships with venture capitalists (VCs)</b>{" "}
									to fund{" "}
									<b>scaleups, franchise expansions, and structured exits,</b>{" "}
									including <b>IPOs and M&A opportunities.</b>
								</li>
							</ol>
						</p>
					</div>
					<div className='tnxs'>
						<Title title='Purpose' />
						<p className='manrope'>
							Our purpose is to <b>institutionalize alternative investments</b>{" "}
							by integrating{" "}
							<b>profitability, social impact, and sustainability</b> into a
							structured investment ecosystem:
							<ol>
								<li>
									<span style={{ fontWeight: 600 }}>
										Maximizing Profitable Returns –
									</span>{" "}
									Operate a{" "}
									<b>fully digitized alternative investment ecosystem</b> based
									on the <b>Broad Venture Builder (BVB) model,</b> enabling
									structured investment opportunities, strategic
									decision-making, and seamless exits while leveraging{" "}
									<b>investor-driven capital growth.</b>
								</li>
								<li>
									<span style={{ fontWeight: 600 }}>
										Creating Positive Economic & Social Impact –
									</span>{" "}
									Facilitate{" "}
									<b>
										remote employment opportunities and decentralized wealth
										creation,
									</b>{" "}
									ensuring fair competition and ethical investment practices
									that are{" "}
									<b>transparent, fraud-free, and socially responsible.</b>
								</li>
								<li>
									<span style={{ fontWeight: 600 }}>
										Aligning Financial Growth with Sustainability –
									</span>{" "}
									Balance{" "}
									<b>short-term gains with long-term, responsible investing,</b>{" "}
									fostering <b>value-driven wealth generation</b> through{" "}
									<b>socially responsible and sustainable business models.</b>
								</li>
							</ol>
						</p>
					</div>
				</div>
			</div>
			<br id='about' />
			<Title title='Profile' />
			<div className='bvb manrope mfc flex'>
				<div className='nts'>
					<a
						href={profile}
						download='csPILLAI company Profile Updated.pdf'
						style={{
							marginTop: "20px",
							marginBottom: "20px",
							padding: "8px",
							border: "1px",
							alignItems: "center",
							display: "flex",
							justifyContent: "center",
							gap: 4,
							width: "170px",
							background: "#F7F7F7",
							borderWidth: "1px",
							textDecoration: "none",
							color: "inherit",
							cursor: "pointer",
						}}
					>
						<img src={pdf} alt='' style={{ height: "20px", width: "20px" }} />
						Download Profile
					</a>

					<NewAccordion items={accordionItems} />
				</div>

				{/* <div className='nts'>
					<p>
						The Broad Venture Builder (BVB) business model at the heart of
						csPILLAI® is dedicated to achieving multiple goals including:
						<br /> 1) innovation, <br /> 2) Entrepreneurship, <br /> 3)
						Employment, & <br /> 4) Rural development. <br /> <br />
						Through its range of services and products, csPILLAI® aims to drive
						positive change in these areas.
					</p>
					<p>
						The name csPILLAI® honors the visionary Mr. C.S. Pillai, a
						pioneering S.C. Railway loco pilot from Gooty R.S., India. The
						pronunciation of csPILLAI® is SEE-YES-PILL-EYE.
					</p>
					<p>
						At its core, csPILLAI® embraces the ideology of Humanism and strives
						to make a positive societal impact. The philosophy followed by
						csPILLAI® revolves around the concept of "making money and doing
						good at the same time" through value-driven innovations.
					</p>
					<p>
						By combining financial success with social responsibility, csPILLAI®
						aims to create a sustainable and impactful investment ecosystem that
						benefits individuals, communities, and the broader society.
					</p>
				</div> */}
			</div>
			<div style={{ width: "100%", paddingLeft: 0 }} className='infs mfc flex'>
				<div className='tnxs'>
					<Title title='Mission' />
					<p className='manrope'>
						"To institutionalize alternative investments through cutting-edge
						AI, enabling investors, entrepreneurs, and businesses to access
						structured, high-yield opportunities while upholding ethical,
						compliant, and transparent investment standards. "
					</p>
				</div>
				<div className='tnxs'>
					<Title title='Vision' />
					<p className='manrope'>
						"To be the global leader in institutionalized alternative
						investments, leveraging AI to structure, scale, and optimize
						investment ecosystems while maintaining ethical integrity,
						sustainability, and long-term stakeholder value creation."
					</p>
				</div>
			</div>
			{/* <img className="sl1" src={slice1} alt="" /> */}
		</div>
	);
}

export default About;
