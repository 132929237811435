import React, { useState } from "react";

const Accordion = ({ items }) => {
	const [activeIndex, setActiveIndex] = useState(null);

	const onAccordionClick = (index) => {
		setActiveIndex(index === activeIndex ? null : index);
	};

	return (
		<div className='accordion'>
			{items.map((item, index) => (
				<div key={index} className='accordion-item'>
					<div
						className='accordion-title'
						onClick={() => onAccordionClick(index)}
					>
						<h3>{item.title}</h3>
						<span>{activeIndex === index ? "-" : "+"}</span>
					</div>
					{activeIndex === index && (
						<div className='accordion-content'>{item.content}</div>
					)}
				</div>
			))}
		</div>
	);
};

export default Accordion;
