import "../css/what.css";
import Title from "./Title";
import Check from "./Check";
import Accordion from "./Accordion";
import NewAccordion from "./NewAccordion";

const items = [
	"Identify Your Investor Type – Visit the 'Services' section to explore investment opportunities that match your profile.",
	"Assess & Select – Review and choose an investment service aligned with your financial goals.",
	"Seamless Onboarding – Create your account and navigate the service platform effortlessly.",
	"Digitized Documentation – Securely sign agreements online with fully compliant digital processing.",
	"Instant Fund Deployment – Invest your funds and track real-time progress with structured investment reporting.",
	"Business Management & Mentorship – Manage your investment remotely or engage as a mentor for growing businesses.",
	"Maximized Returns – Enjoy structured exits and high-yield investment returns aligned with CAGR growth models.",
];

const accordionItems = [
	{
		title: "Investing with csPILLAI® is Simple & Transparent",
		content: (
			<>
				<div className='manrope'>
					We integrate sustainability, digital accessibility, and structured
					alternative investments into every step of the process. Here’s how it
					works:
					<p>
						✓ Identify Your Investor Type – Visit the 'Services' section to
						explore investment opportunities that match your profile.
					</p>
					<p>
						✓ Assess & Select – Review and choose an investment service aligned
						with your financial goals.
					</p>
					<p>
						✓ Seamless Onboarding – Create your account and navigate the service
						platform effortlessly.
					</p>
					<p>
						✓ Digitized Documentation – Securely sign agreements online with
						fully compliant digital processing.
					</p>
					<p>
						✓ Instant Fund Deployment – Invest your funds and track real-time
						progress with structured investment reporting.
					</p>
					<p>
						✓ Business Management & Mentorship – Manage your investment remotely
						or engage as a mentor for growing businesses.
					</p>
					<p>
						✓ Maximized Returns – Enjoy structured exits and high-yield
						investment returns aligned with CAGR growth models.
					</p>
					<div>
						By following these steps, csPILLAI® ensures a seamless integration
						into our industrialized investment ecosystem, providing you with
						structured, high-growth opportunities while maintaining sustainable
						and socially responsible investing.
					</div>
				</div>
			</>
		),
	},
];

function HiW() {
	return (
		<div id='how?' className='container what_cnt flex mfc justify-between'>
			<div className='pm'>
				<Title subtitle='csPILLAI' title='How It Works—Simple & Seamless' />
				<p className='manrope'>
					At <b>csPILLAI®</b>, our <b>digital investment ecosystem</b> provides
					user-friendly platforms offering{" "}
					<b>structured alternative investment opportunities </b>
					across{" "}
					<b>startups, scaleups, franchises, and mentorship-driven ventures.</b>
					Our mission is to{" "}
					<b>
						streamline investing, promote financial growth, and ensure
						sustainability while generating high-value returns for investors.
					</b>
					<br />
					<br />
					With{" "}
					<b>
						csPILLAI®, investing is seamless, efficient, and fully digitized,
					</b>{" "}
					allowing you to access and manage investments{" "}
					<b>anytime, anywhere.</b>
				</p>

				<NewAccordion items={accordionItems} />
			</div>
			<div id='wiu' className='pm'>
				<div className='iframe'>
					<iframe
						src='https://www.youtube.com/embed/0Kqtxcdtt1g?si=eb-SkT-3xZ3TS0q-'
						title='How it works'
						frameBorder='0'
						allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
						allowFullScreen
					></iframe>
				</div>
				<p className='manrope'>
					If you require further assistance, simply submit your details and
					queries via WhatsApp at +91 8850762306 to our dedicated 'Admins'. They
					will promptly review your message and respond to you as soon as
					possible.
				</p>
			</div>
		</div>
	);
}

export default HiW;
