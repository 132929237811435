import Title from "./Title";
// import svg from "../assets/svg/ideology.svg";
import img from "../assets/ideology.png";
import "../css/ideology.css";
import vr from "../assets/vr.png";

function Ideology(props) {
	return (
		<div id='why?' className='container containerh bg'>
			<Title title='Our Ideology' />
			<div className='flex manrope mfc flex  idl'>
				<div className='imgIdl flex  justify-center align-center'>
					<img src={img} alt='csPillai Ideology' />
				</div>
				<div className='tid'>
					{/* <p>BVB is csPILLAI.</p> */}
					<p>
						At csPILLAI®, we operate on a Broad Venture Builder (BVB) business
						model—an AI-driven investment ecosystem designed to build, scale,
						and facilitate investments in high-growth startups and businesses.
						We believe in "creating wealth with purpose" by leveraging
						innovation and strategic entrepreneurship to drive employment, rural
						development, and economic progress.
						<br />
						<br />
						Through our BVB model, we provide structured investment
						opportunities, ensuring sustainable growth for investors,
						businesses, and communities while fostering long-term success in
						emerging markets.
					</p>
					<div className='flex desktop justify-center align-center'>
						<img src={vr} alt='' />
					</div>
				</div>
			</div>
		</div>
	);
}

export default Ideology;
